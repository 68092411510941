import '../styles/index.css';

// Accordion CSS
import '@components/Accordion/Accordion.css';
import '@components/Accordion/AccordionBody.css';
import '@components/Accordion/AccordionCollapse.css';
import '@components/Accordion/AccordionHeader.css';
import '@components/Accordion/AccordionItem.css';
import '@components/Accordion/AccordionWrap.css';

// AnchorNav CSS
import '@components/AnchorNav/AnchorNav.css';

// Ads/SwitchToTraqq CSS
import '@components/Ads/SwitchToTraqqWideText/SwitchToTraqq.css';
import '@components/Ads/SwitchToTraqqWideText/SwitchToTraqqBg.css';
import '@components/Ads/SwitchToTraqqWideText/SwitchToTraqqText.css';

// Ads/GetOnTraqq CSS
import '@components/Ads/GetOnTraqq/GetOnTraqqBg.css';

// Backdrop CSS
import '@components/Backdrop/Backdrop.css';

// Breadcrumbs CSS
import '@components/Breadcrumbs/Breadcrumbs.css';
import '@components/Breadcrumbs/BreadcrumbsWrap.css';

// Card CSS
import '@components/Card/Card.css';
import '@components/Card/CardBody.css';

// Footer CSS
import '@components/Footer/Footer.css';
import '@components/Footer/FooterAction.css';
import '@components/Footer/FooterCopyright.css';
import '@components/Footer/FooterNav.css';
import '@components/Footer/FooterNavItem.css';

// Grid CSS
import '@components/Grid/Grid.css';

// Hero CSS
import '@components/Hero/Hero.css';

// Info CSS
import '@components/Info/Info.css';

// List CSS
import '@components/List/List.css';
import '@components/List/ListItem.css';

// MailResponse CSS
import '@components/MailResponse/MailResponse.css';

// Nav CSS
import '@components/Nav/Nav.css';
import '@components/Nav/NavItem.css';
// import '@components/Nav/NavLink.css';

// Navbar CSS
import '@components/Navbar/Navbar.css';
import '@components/Navbar/NavbarBrand.css';
import '@components/Navbar/NavbarCollapse.css';
import '@components/Navbar/NavbarCollapseHeader.css';
import '@components/Navbar/NavbarNav.css';
import '@components/Navbar/NavbarSubnav.css';
import '@components/Navbar/NavbarToggler.css';

// Note CSS
import '@components/Note/Note.css';
import '@components/Note/NoteMark.css';

// Card CSS
import '@components/Portal/Portal.css';

// Pricing CSS
import '@components/Pricing/Pricing.css';
import '@components/Pricing/PricingOption.css';
import '@components/Pricing/PricingOptionWrap.css';
import '@components/Pricing/PricingPrice.css';
import '@components/Pricing/PricingPriceWrap.css';
import '@components/Pricing/PricingSeat.css';
import '@components/Pricing/PricingToggle.css';
import '@components/Pricing/PricingTogglePointer.css';

// Reviews CSS
import '@components/Reviews/Reviews.css';
import '@components/Reviews/ReviewsItem.css';
import '@components/Reviews/ReviewsList.css';

// Section CSS
import '@components/Section/Section.css';
import '@components/Section/SectionHeadline.css';

// UI/Alert CSS
import '@components/UI/Alert/Alert.css';

// UI/Badge CSS
import '@components/UI/Badge/Badge.css';

// UI/Blockquote CSS
import '@components/UI/Blockquote/Blockquote.css';
import '@components/UI/Blockquote/BlockquoteAuthor.css';
import '@components/UI/Blockquote/BlockquoteFooter.css';

// UI/Button CSS
// import '@components/UI/Button/Button.css';

// UI/ButtonGroup CSS
import '@components/UI/ButtonGroup/ButtonGroup.css';

// UI/Checkbox CSS
import '@components/UI/Checkbox/Checkbox.css';

// UI/Container CSS
// import '@components/UI/Container/Container.css';

// UI/Divider CSS
import '@components/UI/Divider/Divider.css';

// UI/Field CSS
import '@components/UI/Field/Field.css';
import '@components/UI/Field/FieldFeedback.css';
import '@components/UI/Field/FieldLabel.css';

// UI/Icon CSS
import '@components/UI/Icon/Icon.css';

// UI/Input & UI/Input/FileUploader CSS
import '@components/UI/Input/Input.css';
import '@components/UI/Input/FileUploader/FileUploader.css';
import '@components/UI/Input/FileUploader/FileUploaderDrag.css';
import '@components/UI/Input/FileUploader/FileUploaderFile.css';
import '@components/UI/Input/FileUploader/FileUploaderMessage.css';

// UI/RangeInput
import '@components/UI/RangeInput/RangeInput.css';

// UI/Link CSS
import '@components/UI/Link/Link.css';

// UI/Loading CSS
import '@components/UI/Loading/Loading.css';

// UI/Pagination CSS
import '@components/UI/Pagination/Pagination.css';
import '@components/UI/Pagination/PaginationItem.css';

// UI/Picture CSS
import '@components/UI/Picture/Picture.css';

// UI/Progress CSS
import '@components/UI/Progress/Progress.css';
import '@components/UI/Progress/ProgressBar.css';

// UI/Radio CSS
import '@components/UI/Radio/Radio.css';

// UI/Radials CSS
import '@components/UI/Radials/Radials.css';

// UI/Select CSS
import '@components/UI/Select/Select.css';

// UI/Tabs CSS
import '@components/UI/Tabs/TabButton.css';
import '@components/UI/Tabs/TabContent.css';
import '@components/UI/Tabs/TabLink.css';
import '@components/UI/Tabs/TabPanel.css';
import '@components/UI/Tabs/Tabs.css';

// UI/TimeCounter CSS
import '@components/UI/TimeCounter/TimeCounter.css';

// UI/Typography/Heading CSS
import '@components/UI/Typography/Heading/Heading.css';

// UI/Typography/Paragraph CSS
import '@components/UI/Typography/Paragraph/Paragraph.css';

// UI/Typography/Subtitle CSS
import '@components/UI/Typography/Subtitle/Subtitle.css';

// SwitchToTraqqLayout && SwitchToTraqqNarrowLayout CSS
import '@layouts/Ads/SwitchToTraqqLayout/SwitchToTraqqLayout.css';
import '@layouts/Ads/SwitchToTraqqNarrowLayout/SwitchToTraqqNarrowLayout.css';

// GetOnTraqqLayout CSS
import '@layouts/Ads/GetOnTraqqLayout/GetOnTraqqLayout.css';

// Alternatives CSS
import '@screens/Alternatives/Alternatives.css';

// Updates CSS
// import '@screens/Updates/Updates.css';

// Blog CSS
// import '@screens/Blog/Blog.css';
// import '@screens/Blog/BlogAside.css';
// import '@screens/Blog/BlogBannerSignup.css';
// import '@screens/Blog/BlogCard.css';
// import '@screens/Blog/BlogSearch.css';
// import '@screens/Blog/BlogHeading.css';
// import '@screens/Blog/BlogHero.css';
// import '@screens/Blog/BlogNavbar.css';
// import '@screens/Blog/BlogPicture.css';
// import '@screens/Blog/BlogPost.css';
// import '@screens/Blog/BlogSection.css';
// import '@screens/Blog/BlogShare.css';
// import '@screens/Blog/BlogSubtitle.css';

// Contact us CSS
import '@screens/Company/SuggestFeatures.css';

// Demo CSS
import '@screens/Demo/Demo.css';

// 404 CSS
import '@screens/Errors/404/404.css';

import '@screens/Errors/ErrorPage/errorPage.css';

// HelpCenter CSS
import '@screens/HelpCenter/HelpCenter.css';

// Legal CSS
import '@screens/Legal/Legal.css';

// Timers CSS
import '@screens/Timers/Timers.css';

// editor CSS
import 'public/editor/css/editor.css';

import AnimatedPage from '@components/AnimatedPage';
import TopLoadingBar from '@components/TopLoadingBar';

import App from 'next/app';
import React from 'react';
import { RecoilRoot } from 'recoil';

const MyApp = ({ Component, pageProps }) => (
    <RecoilRoot>
        <TopLoadingBar />

        {/*<AnimatedPage>*/}
            <Component
                { ...pageProps }
            />
        {/*</AnimatedPage>*/}
    </RecoilRoot>
);

MyApp.getInitialProps = async (сontext) => {
    const appProps = await App.getInitialProps(сontext);

    return { ...appProps };
};

export default MyApp;
